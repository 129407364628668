import React, { Component } from 'react'
import Header from 'src/components/Header'
import PageHelmet from 'src/components/PageHelmet'
import AllAdvices from 'src/components/AllAdvices'
import Footer from '../components/Footer'
import { graphql } from 'gatsby'
import withLayout from 'src/components/withLayout'
import { injectIntl } from 'react-intl'

class Advices extends Component {
  render() {
    const {
      data: { advices },
      locale,
      changeLocale,
      intl,
    } = this.props
    return (
      <>
        <PageHelmet title={intl.formatMessage({ id: 'home.advices' })} defer={false} />
        <Header full={true} locale={locale} changeLocale={changeLocale} />
        <AllAdvices locale={locale} advices={advices} />
        <Footer locale={locale} />
      </>
    )
  }
}

export const query = graphql`
  query Advices($locale: String!) {
    advices: allDatoCmsAdvice(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          title
          slug
          adviceCategory {
            title
            slug
          }
          image {
            url
            id
            fluid(maxWidth: 1400, imgixParams: { fm: "jpg", auto: "compress" }) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
  }
`

const customProps = {
  localeKey: 'advices',
}

export default withLayout(customProps)(injectIntl(Advices))

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Link } from 'gatsby'
import { FormattedMessage } from 'react-intl'
import { OutboundLink } from 'gatsby-plugin-google-gtag'
import { goToTop } from 'react-scrollable-anchor'
import logo_en from 'src/img/heroes_logo_full_en.svg'
import logo_ru from 'src/img/heroes_logo_full_ru.svg'
import FacebookIcon from '../svg/FacebookIcon'
import YoutubeIcon from '../svg/YoutubeIcon'
import InstagramIcon from '../svg/InstagramIcon'
import TelegramIcon from '../svg/TelegramIcon'
import LinkedInIcon from '../svg/LinkedInIcon'
import styles from './Footer.module.scss'
import { isOnline, removeListeners, getLink } from 'src/utils'

class Footer extends Component {
  state = { isOnline: true }

  componentDidMount() {
    isOnline(this)
  }

  componentWillUnmount() {
    removeListeners()
  }

  render() {
    const { className, locale } = this.props

    const onlineLinks = (
      <>
        <li>
          <Link to={getLink(locale, 'podcasts')}>
            <FormattedMessage id="home.podcastsButton" />
          </Link>
        </li>
      </>
    )

    const offlineLinks = (
      <>
        <li className={styles.offlineMode}>
          <Link to={getLink(locale, 'podcasts')}>
            <FormattedMessage id="home.podcastsButton" />
          </Link>
        </li>
      </>
    )

    return (
      <footer className={classNames(className, styles.root)}>
        <div className={styles.row}>
          <Link to={getLink(locale)} className={styles.logo}>
            {locale === 'ru' ? (
              <img className={styles.logoImage} src={logo_ru} alt="logo" />
            ) : (
              <img className={styles.logoImage} src={logo_en} alt="logo" />
            )}
            <figure className={styles.beta}>BETA</figure>
          </Link>
          <nav className={styles.nav}>
            <ul>
              <li>
                <Link to={getLink(locale, 'news-list')}>
                  <FormattedMessage id="home.newsButton" />
                </Link>
              </li>
              <li>
                <Link to={getLink(locale, 'interviews')}>
                  <FormattedMessage id="home.interviewButton" />
                </Link>
              </li>
              <li>
                <Link to={getLink(locale, 'videos')}>
                  <FormattedMessage id="home.videosButton" />
                </Link>
              </li>
              {this.state.isOnline && locale !== 'en' ? onlineLinks : offlineLinks}
            </ul>
            <ul>
              <li>
                <Link to={getLink(locale, 'advices')}>
                  <FormattedMessage id="home.advicesButton" />
                </Link>
              </li>
              {/* ***Temporarily disabled *** */}
              {/* <li>
                <Link to={getLink(locale, 'jobs')}>
                  <FormattedMessage id="home.jobsButton" />
                </Link>
              </li> */}
              <li>
                <a href={`https://about.theheroes.media/${locale}`} target="_blank" rel="noopener noreferrer">
                  <FormattedMessage id="home.aboutButton" />
                </a>
              </li>
              <li>
                <Link to={getLink(locale, 'contacts')}>
                  <FormattedMessage id="home.contactsButton" />
                </Link>
              </li>
            </ul>
          </nav>

          <ul className={styles.social}>
            <li>
              <OutboundLink
                href="https://www.facebook.com/theheroesmedia"
                target="_blank"
                rel="noreferrer noopener"
                aria-label="facebook"
              >
                <FacebookIcon width="48px" height="48px" />
              </OutboundLink>
            </li>
            <li>
              <OutboundLink
                href="https://www.youtube.com/channel/UCcho2heT-H7UaPDxd7REX9g?view_as=public"
                target="_blank"
                rel="noreferrer noopener"
                aria-label="youtube"
              >
                <YoutubeIcon width="48px" height="48px" />
              </OutboundLink>
            </li>
            <li>
              <OutboundLink
                href="https://instagram.com/theheroes.media"
                target="_blank"
                rel="noreferrer noopener"
                aria-label="instagram"
              >
                <InstagramIcon width="48px" height="48px" />
              </OutboundLink>
            </li>
            <li>
              <OutboundLink
                href="https://t.me/THEHEROESMEDIA"
                target="_blank"
                rel="noreferrer noopener"
                aria-label="instagram"
              >
                <TelegramIcon width="48px" height="48px" />
              </OutboundLink>
            </li>
            <li>
              <OutboundLink
                href="https://www.linkedin.com/company/the-heroes-media/"
                target="_blank"
                rel="noreferrer noopener"
                aria-label="instagram"
              >
                <LinkedInIcon width="48px" height="48px" />
              </OutboundLink>
            </li>
          </ul>
        </div>

        <div className={styles.extra}>
          <div className={styles.copyright}>
            <span>
              <FormattedMessage id="home.footerCopyright" />
              {new Date().getFullYear()}
            </span>
          </div>
          <div onClick={goToTop} className={styles.toTop}>
            <FormattedMessage id="home.topButton" />
            ↑
          </div>
        </div>
      </footer>
    )
  }
}

Footer.propTypes = {
  className: PropTypes.string,
  locale: PropTypes.string.isRequired,
}

export default Footer

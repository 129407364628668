import React from 'react'

const FacebookIcon = (props) => (
  <svg height="485pt" viewBox="0 0 485 485" width="485pt" {...props}>
    <path
      d="M200.766 400h65.265V242.414h43.801s4.102-25.43 6.102-53.234h-49.649v-36.266c0-5.418 7.11-12.695 14.156-12.695h35.563V85h-48.367c-68.477 0-66.871 53.082-66.871 61.008v43.355h-31.77v53.032h31.77zm0 0"
      fill="#FFF"
    />
    <path d="M0 0v485h485V0zm455 455H30V30h425zm0 0" fill="#151515" />
  </svg>
)

export default FacebookIcon

import React, { Component, Fragment } from 'react'
import Img from 'gatsby-image'
import styles from './Circle.module.scss'
import classNames from 'classnames'

class Circle extends Component {
  render() {
    const { isVisible, position, img } = this.props

    return (
      <Fragment>
        <div
          isVisible={isVisible}
          className={classNames(styles.root, { [styles.isVisible]: isVisible })}
          style={{ top: position.top, left: position.left }}
        >
          <Img fluid={img.fluid} className={styles.image} />
        </div>

        <svg width="700" height="700" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <clipPath id="mask" className={styles.mask}>
              <path
                transform="translate(300,300)"
                d="M127.9,-168.4C169.6,-145.7,209.9,-113.5,231.9,-69.7C253.9,-25.9,257.7,29.6,242,78.8C226.3,128.1,191,171.3,147.4,198.9C103.8,226.6,51.9,238.8,2.4,235.6C-47.2,232.3,-94.4,213.6,-129.2,183.1C-163.9,152.5,-186.1,110.1,-196.1,66.7C-206.1,23.3,-203.8,-21.1,-195.1,-68.1C-186.3,-115.1,-171.1,-164.8,-137.4,-190C-103.8,-215.3,-51.9,-216.1,-4.4,-210.1C43.1,-204,86.2,-191,127.9,-168.4Z"
                fill="#eb144c"
              />
            </clipPath>
          </defs>
        </svg>
      </Fragment>
    )
  }
}

export default Circle

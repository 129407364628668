import React from 'react'

const SvgComponent = (props) => (
  <svg height="485pt" viewBox="0 0 485 485" width="485pt" {...props}>
    <path
      d="M124.535 347.922c39.059 4.351 78.586 4.379 117.95 4.351 39.374.028 78.91 0 117.952-4.351 16.493-1.844 30.196-14.211 34.055-30.961 5.492-23.848 5.508-49.902 5.508-74.465 0-24.559.05-50.601-5.445-74.45-3.86-16.765-17.559-29.116-34.059-30.956-39.055-4.367-78.59-4.383-117.945-4.367-39.364-.016-78.91 0-117.973 4.367-16.476 1.84-30.18 14.191-34.023 30.957C85.066 191.895 85 217.937 85 242.5s0 50.613 5.496 74.46c3.84 16.75 17.543 29.118 34.04 30.962zm77.32-160.692c33.375 17.415 66.54 34.708 100.137 52.239-33.5 17.367-66.668 34.574-100.137 51.93zm0 0"
      fill="#FFF"
    />
    <path d="M0 0v485h485V0zm455 455H30V30h425zm0 0" fill="#151515" />
  </svg>
)

export default SvgComponent

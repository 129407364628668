import React from 'react'

const LinkedInIcon = (props) => (
  <svg height="485pt" viewBox="0 0 485 485" width="485pt" {...props}>
    <path
      d="M89.016 189.906h67.507v203.098H89.016zm0 0M122.324 162.188h.446c23.55 0 38.195-15.602 38.195-35.102-.445-19.93-14.645-35.09-37.754-35.09-23.106 0-38.211 15.16-38.211 35.09 0 19.5 14.68 35.102 37.324 35.102zm0 0M261.398 393.004v-113.43c0-6.086.465-12.117 2.243-16.457 4.886-12.148 15.976-24.691 34.64-24.691 24.438 0 34.196 18.629 34.196 45.918V393H400V276.535c0-62.379-33.309-91.398-77.742-91.398-35.871 0-51.887 19.742-60.86 33.547v.664h-.441c.117-.22.309-.442.441-.664v-28.778h-67.535c.907 19.051 0 203.094 0 203.094h67.535zm0 0"
      fill="#FFF"
    />
    <path d="M0 0v485h485V0zm455 455H30V30h425zm0 0" fill="#151515" />
  </svg>
)

export default LinkedInIcon

import React, { Component } from 'react'
import classNames from 'classnames'
import IntersectionVisible from 'react-intersection-visible'
import { FormattedMessage } from 'react-intl'
import Circle from './Circle'
import { Link } from 'gatsby'
import { getLink, getStyles, blobPositions } from 'src/utils'
import styles from './Item.module.scss'

class Item extends Component {
  state = {
    isVisible: false,
  }

  onHide() {
    this.setState({ isVisible: false })
  }

  onShow() {
    this.setState({ isVisible: true })
  }

  render() {
    const {
      color,
      node: { title, slug, image, adviceCategory },
      locale,
      isFirstItem,
    } = this.props

    return (
      <>
        {title && (
          <div
            className={classNames(styles.root, { [styles.isFirstItem]: isFirstItem })}
            style={{ backgroundColor: color.bg, color: color.color }}
          >
            <div className={styles.generalWrapper}>
              <div className={styles.inner}>
                <Link
                  to={getLink(locale, `advice-category/${adviceCategory.slug}`)}
                  className={styles.adviceCategoryLink}
                  style={{ color: color.color }}
                >
                  <p className={styles.categoryTitle}>{adviceCategory.title}</p>
                </Link>
                <Link to={getLink(locale, `advice/${slug}`)} className={styles.title}>
                  {title && title.length >= 120 ? `${title.substring(0, 120)}...` : title}
                </Link>

                <Link
                  to={getLink(locale, `advice/${slug}`)}
                  className={styles.link}
                  style={{ backgroundColor: color.color, color: color.bg }}
                >
                  <FormattedMessage id="home.becomeBetter" />
                </Link>
              </div>
              <IntersectionVisible
                className={styles.visibleWrapper}
                onHide={(e) => this.onHide(e)}
                onShow={(e) => this.onShow(e)}
              >
                <Circle
                  isVisible={this.state.isVisible}
                  position={getStyles(blobPositions, blobPositions.length)}
                  img={image}
                />
              </IntersectionVisible>
            </div>
          </div>
        )}
      </>
    )
  }
}

export default Item

import React from 'react'

const TelegramIcon = (props) => (
  <svg height="485pt" viewBox="0 0 485 485" width="485pt" {...props}>
    <path
      d="M90.563 236.875l72.585 27.09 28.094 90.351c1.797 5.786 8.871 7.922 13.567 4.082l40.46-32.98a12.062 12.062 0 0 1 14.711-.41l72.977 52.98c5.023 3.649 12.14.899 13.402-5.172l53.457-257.136c1.375-6.633-5.14-12.164-11.453-9.723L90.48 220.871c-7.351 2.836-7.289 13.242.083 16.004zm96.152 12.672l141.851-87.367c2.547-1.567 5.172 1.882 2.98 3.914L214.48 274.914a24.266 24.266 0 0 0-7.523 14.52l-3.984 29.554c-.532 3.946-6.075 4.336-7.164.516l-15.336-53.89c-1.754-6.145.804-12.712 6.242-16.067zm0 0"
      fill="#FFF"
    />
    <path d="M0 0v485h485V0zm455 455H30V30h425zm0 0" fill="#151515" />
  </svg>
)

export default TelegramIcon

import React, { Component } from 'react'
import styles from './AllAdvices.module.scss'
import Item from './Item'
import { getStyles, colors } from 'src/utils'

class AllAdvices extends Component {
  render() {
    const {
      advices: { edges },
      locale,
    } = this.props

    const advices = [...edges]

    return (
      <div className={styles.root}>
        <Item
          key={advices[0].node.title}
          locale={locale}
          {...advices[0]}
          color={getStyles(colors, colors.length)}
          isFirstItem
        />

        {advices
          .slice(1)
          .map((item) => (
            <Item key={item.node.title} locale={locale} {...item} color={getStyles(colors, colors.length)} />
          ))}
      </div>
    )
  }
}

export default AllAdvices
